<!-- src/views/WebhookHandler.vue -->
<template>
  <div>
    <!-- This view doesn't need any UI -->
  </div>
</template>

<script>
import gql from "graphql-tag";
import userapiTaskStatusChanged from "@/graphql/mutations/userapiTaskStatusChanged.mutation.graphql";

export default {
    name: "UserapiWebhookHandler",
    async created() {

        console.log("90909");
        const taskHash = this.$route.query.hash || this.$route.params.hash;
        const taskData = JSON.stringify(this.$route.query || this.$route.params);

        alert("qwerty");
        console.log(taskData);
        try {
            await this.$apollo.mutate({
                mutation: gql(userapiTaskStatusChanged),
                variables: {
                    taskHash,
                    taskData
                },
                client: "chatClient",
            });
            console.log("Mutation sent successfully");
        } catch (error) {
            console.error("GraphQL mutation error:", error);
        }
    }
};
</script>
